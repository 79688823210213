import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {Feedback} from "../../../model/feedback";
import {MainService} from "../../../services/main.service";
import {NgsRevealConfig} from "ngx-scrollreveal";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.sass']
})
export class FeedbackComponent implements OnInit, AfterViewChecked {

  init = false;

  singleConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: false,
    focusOnSelect: false,
    infinite: true,
    useTransform: true,
    cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)'
  };

  navConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    fade: false,
    infinite: false,
    arrows: true,
    prevArrow: '<button class="btn-slider btn-prev"></button>',
    nextArrow: '<button class="btn-slider btn-next"></button>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  feedbacks$: Feedback[];

  config: NgsRevealConfig;

  constructor(private mainService: MainService, private sanitizer: DomSanitizer) {
    this.config = {
      delay: 300, reset: false, afterReveal: function (el) {
        el.classList.add('is-visible');
      }, afterReset: function (el) {
        el.classList.remove('is-visible');
      }
    };
  }

  ngAfterViewChecked(): void {
    let single = $('.reviews-slider');
    let nav =  $('.reviews-thumb-slider');

    if (!this.init && single.length > 0 && nav.length > 0 && this.feedbacks$ !== undefined){
      this.init = true;
      this.mainService.initSliderSyncing(single, nav, this.singleConfig, this.navConfig);
    }
  }

  ngOnInit(): void {
    this.mainService.loadFeedback(false)
      .subscribe((data: Feedback[]) => {
        this.feedbacks$ = data;
        this.feedbacks$.forEach(feedback => {
          if (feedback.video) {
            feedback.videoSafe = this.sanitizer.bypassSecurityTrustResourceUrl(feedback.video);
          }
        });
      });
  }

  playVideo(event) {
    let mediaBlock = event.target;
    let iframe = mediaBlock.firstChild;

    // @ts-ignore
    $('.reviews-thumb-slider').slick('slickPause');
    // @ts-ignore
    $('.reviews-slider').slick('slickPause');

    mediaBlock.classList.remove('video');
    iframe.style.visibility = "visible";
    iframe.src += "?autoplay=1";
  }

}
