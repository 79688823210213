import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LifeService {

  constructor(private http:HttpClient) { }

  loadTags(){
    return this.http.get('/api/article-tags/get-tags');
  }

  loadArticles(n: number){
    return this.http.get(`/api/articles/get-top/${n}`);
  }

  loadArticlesByTag(id: number, top: number){
    return this.http.get(`/api/articles/get-by-tag/${id}?top=${top}`);
  }

  loadArticle(id: string){
    return this.http.get(`/api/articles/get/${id}`);
  }

  loadGallery(id: string){
    return this.http.get(`/api/gallery/get-by-article/${id}`);
  }


}
