import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {MainService} from "../../services/main.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  title = 'GOLIVE! — IT Solutions for Your Business';

  constructor(private titleService: Title, private mainService: MainService) {
  }

  ngOnInit(): void {
    this.mainService.setHeaderClass("header-home");
    this.titleService.setTitle(this.title);
  }

  ngOnDestroy() {
    this.mainService.setHeaderClass("");
  }
}
