<section class="black-block section-animate" [ngsReveal]="config">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="wrapper">
          <div class="core-prod-block">
            <h2 class="title">Core products</h2>
            <ngx-slick-carousel class="core-slider"
                                *ngIf="$products as products"
                                [config]="coreSlider">
              <div class="item"
                   ngxSlickItem
                   *ngFor="let product of products">
                <div class="cs-box">
                  <div class="desc">
                    <div class="title">{{product.name}}</div>
                    <p>{{product.description}}</p>
                    <p>
                      <a *ngIf="product.link; else noLink1" [href]="product.link" class="more" target="_blank" (click)="coreProductsClick(product.name)">Details</a>
                      <ng-template #noLink1>
                        <a routerLink="/our-solutions/4" class="more" (click)="coreProductsClick(product.name)">Details</a>
                      </ng-template>
                    </p>
                  </div>
                  <div class="bg-overlay" [ngStyle]="{'background-image': 'url(' + product.imageUrl + ')'}"></div>
                  <a *ngIf="product.link; else noLink2" [href]="product.link" class="overlay" target="_blank"></a>
                  <ng-template #noLink2>
                    <a routerLink="/our-solutions/4" class="overlay"></a>
                  </ng-template>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
          <div class="partners-block">
            <h2 class="title"><span>Our partners</span></h2>
            <ngx-slick-carousel class="partners-slider"
                                *ngIf="$partners as partners"
                                [config]="partnersSlider">
              <div class="item"
                   ngxSlickItem
                   *ngFor="let partner of partners">
                <div class="wrap">
                  <a [href]="partner.link" target="_blank" [title]="partner.name">
                    <img [src]="partner.logo" [alt]="partner.name"></a>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
