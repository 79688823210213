<section class="articles-block {{sectionClass}}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="title">Interesting things in recent days</h2>
        <div class="wrapper"
             *ngIf="articles$ as articles">
          <div class="article-box"
                *ngFor="let article of articles">
            <div class="image"><a [routerLink]="article.link"><img [src]="article.imageUrl" [alt]="article.title"></a></div>
            <div class="date">{{article.date}}</div>
            <div class="title"><a [routerLink]="article.link">{{article.title}}</a></div>
            <div class="tags">
              <a [routerLink]="article.link" *ngFor="let tag of article.tags">#{{tag.name}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
