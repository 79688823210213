import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.sass']
})
export class AboutUsComponent implements OnInit, AfterViewChecked {

  title = "GOLIVE! | About us";
  init = false;

  constructor(private titleService: Title) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }

  ngAfterViewChecked(): void {
    let menu = $('.menu-about-us ul li');
    let content = $('.about-us-page.content-page');

    if (!this.init && menu.length > 0 && content.length > 0) {
      this.init = true;

      content.not(":first").addClass('hidden');
      menu.click(function () {
        menu.removeClass("active").eq($(this).index()).addClass("active");
        content.addClass('hidden').eq($(this).index()).removeClass('hidden');
      }).eq(0).addClass("active");
    }
  }

}
