import { Component, OnInit } from '@angular/core';
import {MainService} from "../../../services/main.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.sass']
})
export class CookiesComponent implements OnInit {

  constructor(private mainService: MainService, public router: Router) { }

  ngOnInit(): void {
    if (window.location.hostname === "golive.md"){
      this.appendCookiesBlock();
    }
  }


  appendCookiesBlock(){
    let src = 'https://consent.cookiebot.com/f3190d7b-c0b2-4d6d-8453-18cc7907b322/cd.js';
    let attrs = [{
      name: 'id',
      value: 'CookieDeclaration'
    }];

    // @ts-ignore
    this.mainService.appendScript(src, $('.content-page .col-lg-12'), attrs);
  }
}
