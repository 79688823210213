import {AfterViewChecked, Component, Input, OnInit} from '@angular/core';
import {Contact} from "../../../model/contact";
import {SocialNetwork} from "../../../model/social-network";
import {MainService} from "../../../services/main.service";
import {GoogleAnalyticsService} from "../../../services/google-analytics.service";

declare var ymaps: any;

declare var getFormData: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit, AfterViewChecked {

  contact$: Contact;

  @Input()
  socialNetworks$: SocialNetwork[];

  init = false;

  messageIsSent = false;

  constructor(private mainService: MainService, private googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.mainService.loadContacts()
      .subscribe((data: Contact) => {
        this.contact$ = data;
      });
  }

  ngAfterViewChecked(): void {
    let map = document.getElementById('map');

    if (!this.init && map) {
      this.init = true;

      ymaps.ready(init);

      function init() {
        let myMap = new ymaps.Map('map', {
          center: [46.997058, 28.819769],
          zoom: 16
        });

        let myPlacemark = new ymaps.Placemark(
          [46.997058, 28.819769], {
            hintContent: ''
          }, {
            iconLayout: 'default#image',
            iconImageHref: 'assets/img/marker.png', // картинка иконки
            iconImageSize: [60, 60], // размеры картинки
            iconImageOffset: [-35, -60] // смещение картинки
          });

        myMap.controls.add('zoomControl');
        myMap.geoObjects.add(myPlacemark);
      }
    }
  }

  validateEmail(email) {
    let pattern = new RegExp("^([a-z0-9]+[.\\-_]?[a-z0-9]+)+@([a-z0-9]+[.\\-]?[a-z0-9]+)+\\.([a-z]+[.]?[a-z]+)$");
    return pattern.test(email.toLowerCase());
  }

  validatePhone(phone) {
    let pattern = new RegExp("^\\+?[0-9]{5,16}$");
    return pattern.test(phone);
  }

  validateForm($form) {
    let fullNameEl = $form.find('[name=fullName]');
    let fullName = fullNameEl.val();

    if (fullName.length == 0) {
      fullNameEl.focus();
      return false;
    }

    let emailEl = $form.find('[name=email]');
    let email = emailEl.val();

    if (email.length == 0 || !this.validateEmail(email)) {
      emailEl.focus();
      return false;
    }

    let phoneEl = $form.find('[name=phone]');
    let phone = phoneEl.val();

    if (phone && !this.validatePhone(phone)) {
      phoneEl.focus();
      return false;
    }

    let messageEl = $form.find('[name=message]');
    let message = messageEl.val();

    if (message.length == 0) {
      messageEl.focus();
      return false;
    }

    return true;
  }

  sendMessage() {
    this.googleAnalyticsService.eventEmitter("contact_us", "contact_us", "send_message");

    if (!this.messageIsSent) {
      let form = $(document.getElementById('form'));

      if (this.validateForm(form)) {
        let data = getFormData(form);
        this.mainService.sendMessage(data).subscribe((response: any) => {
          if (response.type == 0 || response.type == 1) {
            form.find('input, textarea').attr('readonly', 'readonly');
            let button = form.find('button');
            button.attr('disabled', 'disabled');
            button.addClass('disabled');
            button[0].innerText = response.message;
          }
        });
      }
    }
  }

  socialNetworkClick(name: string){
    this.googleAnalyticsService.eventEmitter("view_social_network", "social_networks", name);
  }

}
