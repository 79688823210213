<section class="title-block" style="background-image: url(assets/img/bg-solutions.svg)">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="wrapper">
          <h1 class="title">Terms of Service</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our-story-page content-page">
  <div class="container" *ngIf="text">
    <div class="row">
      <div class="col-lg-12" [innerHTML]="text"></div>
    </div>
  </div>
</section>
