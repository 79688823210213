import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {HomeComponent} from "./components/home/home.component";

const routes: Routes = [
  {path: '', loadChildren: () => import('src/app/modules/home.module').then(m => m.HomeModule)},
  {path: '', loadChildren: () => import('src/app/modules/main.module').then(m => m.MainModule)},
  {path: '', loadChildren: () => import('src/app/modules/about-us.module').then(m => m.AboutUsModule)},
  {path: '', loadChildren: () => import('src/app/modules/shared.module').then(m => m.SharedModule)},
  {path: '**', component: HomeComponent, pathMatch: 'full'},
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
