import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {DomSanitizer, Title} from "@angular/platform-browser";
import {TechnologyType} from "../../model/technology-type";
import {MainService} from "../../services/main.service";

@Component({
  selector: 'app-our-knowledge',
  templateUrl: './our-knowledge.component.html',
  styleUrls: ['./our-knowledge.component.sass']
})
export class OurKnowledgeComponent implements OnInit, AfterViewChecked {

  title = "GOLIVE! | Our knowledge";
  init = false;

  technologyTypes$: TechnologyType[];

  constructor(private titleService: Title, private mainService: MainService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.mainService.loadTechnologyTypes(true)
      .subscribe((data: TechnologyType[]) => {
        this.technologyTypes$ = data;

        this.technologyTypes$.forEach(techType => {
          techType.descriptionSafe = this.sanitizer.bypassSecurityTrustHtml(techType.description);
        })
      });

  }

  ngAfterViewChecked(): void {
    let tab = $(".our-knowledge-page .wrapper .tab");
    let tabItem = $(".our-knowledge-page .tab_item");

    if (!this.init && tab.length > 0 && tabItem.length > 0) {
      this.init = true;

      tabItem.not(":first").hide();
      tab.click(function () {
        tab.removeClass("active").eq($(this).index()).addClass("active");
        tabItem.hide().eq($(this).index()).fadeIn()
      }).eq(0).addClass("active");

      $(".our-knowledge-page .btn-open-tab-mobile").click(function () {
        $(".our-knowledge-page .tabs").toggleClass("active");
      });

      $(".our-knowledge-page .tabs .tab").click(function () {
        $(".our-knowledge-page .tabs").removeClass("active");
      });
    }
  }

}
