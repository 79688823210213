<section class="main-block section-animate is-visible">
  <div class="center">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <ngx-slick-carousel class="slider-main"
                              *ngIf="sliders$ as sliders"
                              [config]="sliderMain"
                              [ngsReveal]="config">
            <div class="item"
                 ngxSlickItem
                 *ngFor="let slider of sliders">
              <h3>{{slider.title}}</h3>
              <p>{{slider.description}}</p>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>

  <div class="service-block"
       *ngIf="services$ as services"
       [ngsReveal]="config">
    <div class="item item-{{i+1}}"
         *ngFor="let service of services; index as i; trackBy: serviceTrack">
      <div class="title">{{service.title}}</div>
      <div class="desc">
        <p>{{service.shortDescription}}</p>
      </div>
      <a routerLink="{{service.link}}" class="overlay"></a>
    </div>
  </div>

  <ngx-slick-carousel class="slider-bg"
                      *ngIf="sliders$ as sliders"
                      [config]="sliderBg">
    <div ngxSlickItem *ngFor="let slider of sliders; trackBy: sliderTrack" class="item">
      <div class="bg" [ngStyle]="{'background-image': 'url(' + slider.imageUrl + ')'}"></div>
    </div>
  </ngx-slick-carousel>

</section>
