<section class="title-block" style="background-image: url(assets/img/bg-solutions.svg)">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="wrapper">
          <h1 class="title">Our solutions</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our-solutions-page content-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h3 class="subtitle">Do you need anything else? We are open to new technologies!</h3>

        <div class="wrapper" *ngIf="services$ as services">
          <button class="btn-open-tab-mobile"><i class="demo-icon icon-open-menu"></i> Open tabs</button>
          <div class="tabs">
            <span class="tab" *ngFor="let service of services">{{service.title | uppercase}}</span>
          </div>
          <div class="tab_content">
            <div class="tab_item" *ngFor="let service of services" [innerHTML]="service.descriptionSafe">
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<app-article [sectionClass]="'article-our-knowledge'" [config]="revealConfig"></app-article>
