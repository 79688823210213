import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {WorkStep} from "../../../model/work-step";
import {MainService} from "../../../services/main.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-how-we-work',
  templateUrl: './how-we-work.component.html',
  styleUrls: ['./how-we-work.component.sass']
})
export class HowWeWorkComponent implements OnInit, AfterViewChecked {

  workSteps$: WorkStep[];

  intro$: string;

  init = false;

  constructor(private mainService: MainService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.mainService.loadWorkSteps(true)
      .subscribe((data: WorkStep[]) => {
        this.workSteps$ = data;
        this.workSteps$.forEach(workStep => {
          workStep.descriptionSafe = this.sanitizer.bypassSecurityTrustHtml(workStep.description);
        })

      });

    this.mainService.loadHowWeWorkIntro()
      .subscribe((result: any) => {
        this.intro$ = result.data;
      });
  }

  ngAfterViewChecked(): void {

    let tab = $(".how-work-page .wrapper .tab");
    let tabItem = $(".how-work-page .tab_item");

    if (!this.init && tab.length > 0 && tabItem.length > 0 && this.workSteps$.length > 0) {
      this.init = true;

      tabItem.not(":first").hide();
      tab.click(function () {
        tab.removeClass("active").eq($(this).index()).addClass("active");
        tabItem.hide().eq($(this).index()).fadeIn()
      }).eq(0).addClass("active");
    }
  }

}
