<section class="title-block" style="background-image: url(assets/img/bg-about.svg)">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="wrapper">
          <h1 class="title">About us</h1>
          <div class="menu-about-us">
            <ul>
              <li>How we work</li>
              <li>Our team</li>
              <li>Our partners</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-how-we-work></app-how-we-work>
<app-our-story></app-our-story>
<app-our-partners></app-our-partners>
