
<section class="title-block" style="background-image: url(assets/img/bg-our-life.svg)">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="wrapper">
          <h1 class="title">Our life</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our-life-page content-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">

        <div class="tags" *ngIf="tags$ as tags">
          <a (click)="clickTag($event, tag.id)" *ngFor="let tag of tags; trackBy: articleTrack">#{{tag.name}}</a>
        </div>

        <div class="wrapper" *ngIf="articles$ as articles">
          <div class="article-box" *ngFor="let article of articles">
            <div class="image">
              <a href="">
                <img [src]="article.imageUrl" [alt]="article.title">
              </a>
            </div>
            <div class="date">{{article.date}}</div>
            <div class="title">
              <a href="#">{{article.title}}</a>
            </div>
            <div class="tags">
              <a *ngFor="let tag of article.tags">#{{tag.name}}</a>
            </div>
            <a [routerLink]="article.link" class="overlay"></a>
          </div>
        </div>
        <button class="btn-more" (click)="seeMore()">See more</button>
      </div>
    </div>
  </div>
</section>
