import {Component, OnInit} from '@angular/core';
import {WorkStep} from "../../../model/work-step";
import {MainService} from "../../../services/main.service";
import {NgsRevealConfig} from "ngx-scrollreveal";

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.sass']
})
export class HowItWorksComponent implements OnInit {

  workSteps$: WorkStep[];

  config: NgsRevealConfig;

  constructor(private mainService: MainService) {
    this.config = {
      delay: 300, reset: false, afterReveal: function (el) {
        el.classList.add('is-visible');
      }, afterReset: function (el) {
        el.classList.remove('is-visible');
      }
    };
  }

  ngOnInit(): void {
    this.mainService.loadWorkSteps(false)
      .subscribe((data: WorkStep[]) => {
        this.workSteps$ = data;
      });
  }

  workStepTrack(index: number, workStep: WorkStep){
    return workStep.id;
  }

  toggleActive(identifier: string) {
    $(".hw-desc-block .item#default").toggleClass("active");
    $(".hw-desc-block .item#default .wrap").toggleClass("active");
    $(`.hw-desc-block .item#${identifier}`).toggleClass("active");
    $(`.hw-desc-block .item#${identifier} .wrap`).toggleClass("active");
  }

  onMouseOver(workStep: WorkStep) {
    this.toggleActive(workStep.identifier);
    $(".hw-desc-block").css("background", "url(" + workStep.imageUrl + ") center center no-repeat");
  }

  onMouseOut(workStep: WorkStep) {
    $(".hw-desc-block").css("background", "");
    this.toggleActive(workStep.identifier);
  }

  touchEnd(event, workStep: WorkStep) {
    let touchedBlock = event.target;
    let box;

    if (touchedBlock.classList.contains('hw-box')) {
      box = touchedBlock;
    } else {
      box = touchedBlock.parentElement;
    }

    if (box) {
      let show = !box.classList.contains('active');

      if (show) {
        let boxes = $('.hw-box');
        boxes.removeAttr('style');
        boxes.removeClass('active');

        $(box).css('background', `url(${workStep.imageUrl}) center center no-repeat`);
        box.classList.add('active');
      } else {
        $(box).removeAttr('style');
        box.classList.remove('active');
      }
    }
  }

}
