import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {NgsRevealConfig} from "ngx-scrollreveal";
import {MainService} from "../../services/main.service";
import {Service} from "../../model/service";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-our-solutions',
  templateUrl: './our-solutions.component.html',
  styleUrls: ['./our-solutions.component.sass']
})
export class OurSolutionsComponent implements OnInit, AfterViewChecked {

  title = "GOLIVE! | Our solutions";
  id: string;

  revealConfig: NgsRevealConfig;

  services$: Service[];

  init = false;

  constructor(private titleService: Title, private mainService: MainService, private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.revealConfig = null;
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.mainService.loadServices(true)
      .subscribe((data: Service[]) => {
        this.services$ = data;

        this.services$.forEach(service => {
          service.descriptionSafe = this.sanitizer.bypassSecurityTrustHtml(service.description);
        })
      });

    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  ngAfterViewChecked(): void {
    let tab = $(".our-solutions-page .wrapper .tab");
    let tabItem = $(".our-solutions-page .tab_item");

    if (!this.init && tab.length > 0 && tabItem.length > 0 && this.services$.length > 0) {
      this.init = true;
      let index = 0;

      if (this.id !== null && +this.id > 0 && this.services$.some(x => x.id == +this.id)) {
        let service = this.services$.find(x => x.id == +this.id);
        index = service.index;
      }

      tabItem.not(tabItem.eq(index)).hide();
      tab.click(function () {
        tab.removeClass("active").eq($(this).index()).addClass("active");
        tabItem.hide().eq($(this).index()).fadeIn()
      }).eq(index).addClass("active");

      $(".our-solutions-page .btn-open-tab-mobile").click(function () {
        $(".our-solutions-page .tabs").toggleClass("active");
      });

      $(".our-solutions-page .tabs .tab").click(function () {
        $(".our-solutions-page .tabs").removeClass("active");
      });
    }
  }


}
