<section class="how-work section-animate" [ngsReveal]="config">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="title">How it works</h2>
        <div class="wrapper">
          <div class="hw-desc-block active"
               *ngIf="workSteps$ as workSteps">
            <div class="item active" id="default">
              <div class="wrap active">
                GOLIVE provide full-stack cycle for software development.
              </div>
            </div>
            <div class="item"
                 *ngFor="let workStep of workSteps; trackBy: workStepTrack"
                 id="{{workStep.identifier}}">
              <div class="wrap">
                {{workStep.shortDescription}}
              </div>
            </div>
          </div>
          <div class="hw-block"
               *ngIf="workSteps$ as workSteps">
            <div class="hw-box"
                 *ngFor="let workStep of workSteps; index as i; trackBy: workStepTrack"
                 (mouseover)="onMouseOver(workStep)"
                 (mouseout)="onMouseOut(workStep)"
                 (touchend)="touchEnd($event, workStep)">
              <span class="num">{{i+1 | number: '2.0'}}</span>
              <div class="title">{{workStep.title}}</div>
              <div class="description">{{workStep.shortDescription}}</div>
              <img src="{{workStep.icon}}" alt="{{workStep.title}}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
