<section class="reviews section-animate" [ngsReveal]="config">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="title">Feedback from our clients</h2>
      </div>
    </div>
  </div>
  <div class="reviews-thumb-wrapper">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="reviews-thumb-slider" *ngIf="feedbacks$ as feedbacks">
            <div *ngFor="let feedback of feedbacks">
              <div class="item">
                {{feedback.client}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="reviews-slider" *ngIf="feedbacks$ as feedbacks">
          <div class="item" *ngFor="let feedback of feedbacks">
            <div class="reviews-item">
              <div class="media" [class.video]="feedback.video"
                   (click)="feedback.video ? playVideo($event) : false"
                   [ngStyle]="{'background-image': 'url(' + feedback.image + ')'}">
                <iframe *ngIf="feedback.video" [src]="feedback.videoSafe"></iframe>
              </div>
              <div class="desc">
                <p>{{feedback.text}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
