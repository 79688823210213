import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {LifeService} from "../../../services/life.service";
import {Article} from "../../../model/article";
import {Gallery} from "../../../model/gallery";
import { Meta } from '@angular/platform-browser';
import {GoogleAnalyticsService} from "../../../services/google-analytics.service";

@Component({
  selector: 'app-our-life-details',
  templateUrl: './our-life-details.component.html',
  styleUrls: ['./our-life-details.component.sass']
})
export class OurLifeDetailsComponent implements OnInit, AfterViewChecked {

  title = "GOLIVE! | Our life";
  id: string;
  init = false;

  article$: Article;
  pictures$: Gallery[];

  appendScript(src) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.body.appendChild(script);
  }

  constructor(private titleService: Title, private metaService: Meta, private activatedRoute: ActivatedRoute, private lifeService: LifeService, private sanitizer: DomSanitizer, private googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id !== undefined) {
      this.lifeService.loadArticle(this.id)
        .subscribe((data: Article) => {
          this.article$ = data;
          this.article$.shortTextSafe = this.sanitizer.bypassSecurityTrustHtml(this.article$.shortText);
          this.article$.textSafe = this.sanitizer.bypassSecurityTrustHtml(this.article$.text);

          this.title = "GOLIVE! | " + this.article$.title;
          this.titleService.setTitle(this.title);

          this.metaService.updateTag({property: 'og:title', content: this.article$.title});
          this.metaService.updateTag({property: 'og:description', content: this.article$.shortText});
          this.metaService.updateTag({property: 'og:image', content: this.article$.imageUrl});
        });

      this.lifeService.loadGallery(this.id)
        .subscribe((data: Gallery[]) => {
          this.pictures$ = data;
        })
    }
  }

  ngAfterViewChecked(): void {
    let popupGallery = $('.popup-gallery');
  // let share = $('.share');

    if (!this.init && popupGallery.length > 0) {
      this.init = true;

      // this.appendScript('//yastatic.net/es5-shims/0.0.2/es5-shims.min.js');
      // this.appendScript('//yastatic.net/share2/share.js');

      (<any>popupGallery).magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Загрузка изображения #%curr%...',
        mainClass: 'mfp-fade mfp-img-mobile',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
          tError: '<a href="%url%">Изображение #%curr%</a> не загружено.',
          titleSrc: function (item) {
            return '';
          }
        }
      });
    }
  }

  share(event, name: string){
    this.googleAnalyticsService.eventEmitter('share', 'engagement', name);
  }

}
