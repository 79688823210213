<ng-container *ngIf="article$ as article">
  <section class="title-block our-life-full-title" style="background-image: url(assets/img/bg-our-life.svg)">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="wrapper">
            <h1 class="title">{{article.title}}</h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="our-story-page  our-life-full-page content-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="info-panel">
            <div class="date">{{article.date}}</div>
            <div class="share" *ngIf="false">
              <p>Поделиться</p>
              <div class="ya-share2"
                   data-services="vkontakte,facebook,linkedin"
                   data-size="s"
                   data-image="{{article.imageUrl}}"
                   data-title="{{article.title}}"
                   data-description="{{article.text}}"
              (click)="share($event, article.title)"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="image-box"><img [src]="article.imageUrl" [alt]="article.title"></div>
        </div>
        <div class="col-lg-7 pt20" [innerHTML]="article.shortTextSafe"></div>
        <div class="col-lg-12" [innerHTML]="article.textSafe"></div>
      </div>
    </div>
  </section>


  <section class="gallery" *ngIf="pictures$ as pictures">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="wrapper popup-gallery">
            <div class="photo" *ngFor="let picture of pictures">
              <a [href]="picture.imageUrl">
                <img [src]="picture.imageUrl" [alt]="picture.alterName">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="our-life-full-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="tags" *ngIf="article.tags as tags">
            <a *ngFor="let tag of tags">#{{tag.name}}</a>
          </div>
        </div>
      </div>
    </div>
  </section>

</ng-container>
