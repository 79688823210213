import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ArticleComponent} from "../components/shared/article/article.component";
import {RouterModule} from "@angular/router";
import {NgsRevealModule} from "ngx-scrollreveal";

@NgModule({
  declarations: [
    ArticleComponent
  ],
  exports: [ArticleComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgsRevealModule
  ]
})
export class ArticleModule {
}
