<section class="our-story-page about-us-page content-page">
  <div class="container" *ngIf="story$ as story">
    <div class="row">
      <div class="col-lg-5">
        <div class="image-box"><img [src]="story.imageUrl" alt=""></div>
      </div>
      <div class="col-lg-7 pt20" [innerHTML]="story.shortDescription"></div>
      <div class="col-lg-12" [innerHTML]="story.description"></div>
    </div>
  </div>
</section>
