import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {Feedback} from "../../../model/feedback";
import {MainService} from "../../../services/main.service";

@Component({
  selector: 'app-our-partners',
  templateUrl: './our-partners.component.html',
  styleUrls: ['./our-partners.component.sass']
})
export class OurPartnersComponent implements OnInit, AfterViewChecked {

  init = false;

  singleConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: false,
    autoplay: true,
    infinite: true,
    useTransform: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
  };

  navConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    dots: false,
    fade: false,
    arrows: true,
    prevArrow: '<button class="btn-slider btn-prev"></button>',
    nextArrow: '<button class="btn-slider btn-next"></button>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  feedbacks$: Feedback[];

  intro$: string;

  constructor(private mainService: MainService) {
  }

  ngOnInit(): void {
    this.mainService.loadFeedback(true)
      .subscribe((data: Feedback[]) => {
        this.feedbacks$ = data;
      });

    this.mainService.loadOurPartnersIntro()
      .subscribe((result: any) => {
        this.intro$ = result.data;
      });


  }

  ngAfterViewChecked(): void {
    let slider = $('.our-partners-slider');
    let sliderNav = $('.our-partners-slider-thumb');

    if (!this.init && slider.length > 0 && sliderNav.length > 0 && this.feedbacks$ !== undefined) {
      this.init = true;
      this.mainService.initSliderSyncing(slider, sliderNav, this.singleConfig, this.navConfig);
    }
  }
}
