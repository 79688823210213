<section class="our-partners-page about-us-page content-page">
  <div class="container" *ngIf="feedbacks$ as feedbacks">
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="intro$ as intro">
          {{intro}}
        </div>
        <div class="our-partners-slider-thumb">
          <div class="item" *ngFor="let feedbackThumb of feedbacks">
            <div class="wrap">
              <img [src]="feedbackThumb.companyLogo" [alt]="feedbackThumb.client">
            </div>
          </div>
        </div>

        <div class="our-partners-slider">
          <div class="item" *ngFor="let feedback of feedbacks">
            <div class="slide">
              <div class="row">
                <div class="col-lg-4">
                  <div class="image image-box"><img [src]="feedback.image" [alt]="feedback.client"></div>
                </div>
                <div class="col-lg-8">
                  <div class="desc">
                    <div class="title">
                      <div class="name-company">{{feedback.client}}</div>
                      <div class="city">{{feedback.address}}</div>
                    </div>
                    <div class="wrap">
                      <div class="name">{{feedback.person}}</div>
                      <div class="pos">{{feedback.post}}</div>
                      <p>{{feedback.text}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
