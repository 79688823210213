import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OurLifeComponent} from "../components/our-life/our-life.component";
import {OurLifeDetailsComponent} from "../components/our-life/our-life-details/our-life-details.component";
import {OurSolutionsComponent} from "../components/our-solutions/our-solutions.component";
import {OurKnowledgeComponent} from "../components/our-knowledge/our-knowledge.component";
import {RouterModule, Routes} from "@angular/router";
import {ArticleModule} from "./article.module";

const routes: Routes = [
  {path: 'our-life', component: OurLifeComponent, data: {animation: 'OurLife'}},
  {path: 'our-life/:id', component: OurLifeDetailsComponent, data: {animation: 'OurLifeDetails'}},
  {path: 'our-solutions', component: OurSolutionsComponent, data: {animation: 'OurSolutions'}},
  {path: 'our-solutions/:id', component: OurSolutionsComponent, data: {animation: 'OurSolutions'}},
  {path: 'our-knowledge', component: OurKnowledgeComponent, data: {animation: 'OurKnowledge'}},
];

@NgModule({
  declarations: [
    OurLifeComponent,
    OurLifeDetailsComponent,
    OurSolutionsComponent,
    OurKnowledgeComponent
  ],
  exports: [
    OurLifeComponent,
    OurLifeDetailsComponent,
    OurSolutionsComponent,
    OurKnowledgeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ArticleModule
  ]
})
export class MainModule {
}
