<section class="title-block" style="background-image: url(assets/img/bg-knowledge.svg)">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="wrapper">
          <h1 class="title">Our knowledge</h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our-knowledge-page content-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h3 class="subtitle">Do you need anything else? We are open to new technologies!</h3>
        <div class="wrapper" *ngIf="technologyTypes$ as technologyTypes">
          <button class="btn-open-tab-mobile"><i class="demo-icon icon-open-menu"></i> Open tabs</button>
          <div class="tabs">
            <span class="tab" *ngFor="let type of technologyTypes">{{type.name}}</span>
          </div>
          <div class="tab_content">
            <div class="tab_item" *ngFor="let type of technologyTypes" [innerHTML]="type.descriptionSafe"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-article [sectionClass]="'article-our-knowledge'"></app-article>
