import {Component, Input, OnInit} from '@angular/core';
import {Article} from "../../../model/article";
import {MainService} from "../../../services/main.service";
import {NgsRevealConfig} from "ngx-scrollreveal";

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.sass']
})
export class ArticleComponent implements OnInit {

  articles$: Article[];

  @Input()
  sectionClass: string;

  @Input()
  config: NgsRevealConfig;

  constructor(private mainService:MainService) {
    this.config =  { delay: 300,  reset: false, afterReveal: function (el) {
        el.classList.add('is-visible');
      }, afterReset: function (el) {
        el.classList.remove('is-visible');
      }};
  }

  ngOnInit(): void {
    this.mainService.loadArticles()
      .subscribe((data: Article[]) => {
        this.articles$ = data;
      });
  }

}
