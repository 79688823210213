import {Component, OnInit} from '@angular/core';
import {Slider} from "../../../model/slider";
import {MainService} from "../../../services/main.service";
import {Service} from "../../../model/service";
import {NgsRevealConfig} from 'ngx-scrollreveal';

@Component({
  selector: 'app-main-block',
  templateUrl: './main-block.component.html',
  styleUrls: ['./main-block.component.sass'],
  providers: [MainService, NgsRevealConfig]
})
export class MainBlockComponent implements OnInit {

  sliderMain = {
    autoplay: true,
    dots: true,
    autoplaySpeed: 5000,
    speed: 1000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '',
    nextArrow: '',
    cssEase: "ease-out",
    asNavFor: '.slider-bg'
  };

  sliderBg = {
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.slider-main',
    autoplay: false,
    autoplaySpeed: 5000,
    speed: 1000,
    fade: true,
    cssEase: 'linear',
    focusOnSelect: true
  };

  sliders$: Slider[];
  services$: Service[];

  config: NgsRevealConfig;

  constructor(private mainService: MainService) {
    this.config = {
      delay: 300, reset: false, afterReveal: function (el) {
        el.classList.add('is-visible');
      }, afterReset: function (el) {
        el.classList.remove('is-visible');
      }
    };
  }

  ngOnInit(): void {
    this.mainService.loadSliders()
      .subscribe((data: Slider[]) => {
        this.sliders$ = data;
      });
    this.mainService.loadServices(false)
      .subscribe((data: Service[]) => {
        this.services$ = data
      });
  }

  sliderTrack(index: number, slider: Slider){
    return slider.id;
  }

  serviceTrack(index: number, service: Service){
    return service.id;
  }
}
