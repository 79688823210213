import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from "../components/shared/header/header.component";
import {OverlayComponent} from "../components/shared/overlay/overlay.component";
import {PushyMenuComponent} from "../components/shared/pushy-menu/pushy-menu.component";
import {FooterComponent} from "../components/shared/footer/footer.component";
import {RouterModule, Routes} from "@angular/router";
import {TosComponent} from "../components/shared/tos/tos.component";
import {PrivacyPolicyComponent} from "../components/shared/privacy-policy/privacy-policy.component";
import {CookiesComponent} from "../components/shared/cookies/cookies.component";

const routes: Routes = [
  {path: 'tos', component: TosComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'cookies', component: CookiesComponent},
];

@NgModule({
  declarations: [
    HeaderComponent,
    OverlayComponent,
    PushyMenuComponent,
    FooterComponent,
    TosComponent,
    PrivacyPolicyComponent,
    CookiesComponent
  ],
  exports: [
    HeaderComponent,
    OverlayComponent,
    PushyMenuComponent,
    FooterComponent,
    TosComponent,
    PrivacyPolicyComponent,
    CookiesComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class SharedModule {
}
