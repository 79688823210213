import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SocialNetwork} from "./model/social-network";
import {MainService} from "./services/main.service";
import {NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {slideInAnimation} from "./animations";

declare let gtag: Function;
declare let gtm: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit, AfterViewInit {

  title: string;

  socialNetworks$: SocialNetwork[];

  constructor(private mainService: MainService, public router: Router) {
    if (window.location.hostname === "golive.md") {
      this.prependCookiebotScripts();

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'G-2G0PC3FWJS', {
            'page_path': event.urlAfterRedirects
          });
          gtm(window, document, 'script', 'dataLayer', 'GTM-5M2VK5N');
        }
      });
    }
  }

  ngAfterViewInit(): void {
    let loader = document.getElementById('loader');
    setTimeout(function () {
      $(loader).fadeOut(500, function () {
        this.remove();
      });
    }, 1000);
  }

  ngOnInit(): void {
    this.mainService.loadSocialNetworks()
      .subscribe((data: SocialNetwork[]) => {
        this.socialNetworks$ = data;
      });
  }

  prependCookiebotScripts() {
    let src = 'https://consent.cookiebot.com/uc.js';
    let attrs = [
      {
        name: 'data-cbid',
        value: 'f3190d7b-c0b2-4d6d-8453-18cc7907b322'
      },
      {
        name: 'data-blockingmode',
        value: 'auto'
      },
      {
        name: 'id',
        value: 'Cookiebot'
      }
    ];
    this.mainService.prependScript(src, document.head, attrs);
  }

  prepareRoute(outlet: RouterOutlet){
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
