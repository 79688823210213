import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from "../components/home/home.component";
import {BlackBlockComponent} from "../components/home/black-block/black-block.component";
import {FeedbackComponent} from "../components/home/feedback/feedback.component";
import {HowItWorksComponent} from "../components/home/how-it-works/how-it-works.component";
import {MainBlockComponent} from "../components/home/main-block/main-block.component";
import {TechnologiesComponent} from "../components/home/technologies/technologies.component";
import {RouterModule, Routes} from "@angular/router";
import {NgsRevealModule} from "ngx-scrollreveal";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {ArticleModule} from "./article.module";

const routes: Routes = [
  {path: '', component: HomeComponent}
];

@NgModule({
  declarations: [
    HomeComponent,
    BlackBlockComponent,
    FeedbackComponent,
    HowItWorksComponent,
    MainBlockComponent,
    TechnologiesComponent
  ],
  exports: [
    HomeComponent,
    BlackBlockComponent,
    FeedbackComponent,
    HowItWorksComponent,
    MainBlockComponent,
    TechnologiesComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgsRevealModule,
    SlickCarouselModule,
    ArticleModule
  ]
})

export class HomeModule {
}
