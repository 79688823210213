import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeHtml, Title} from "@angular/platform-browser";
import {MainService} from "../../../services/main.service";

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.sass']
})
export class TosComponent implements OnInit {

  title = "GOLIVE! | Terms of Service";

  text: SafeHtml;

  constructor(private titleService: Title, private mainService: MainService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.mainService.loadTos()
      .subscribe((response: any) => {
        if (response.data){
          this.text = this.sanitizer.bypassSecurityTrustHtml(response.data);
        }
      });
  }

}
