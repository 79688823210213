<section class="tech-stack section-animate wrapper" [ngsReveal]="config">
  <h2 class="title">Technologies we work with</h2>
  <button class="btn-open-tab-mobile"><i class="demo-icon icon-open-menu"></i> Open tabs</button>

  <div class="tabs" #tabs *ngIf="technologyTypes$ as technologyTypes;">
    <span class="tab"
          *ngFor="let type of technologyTypes; first as isFirst"
          [class.active]="isFirst"
          (click)="getTechnologiesByType($event, type.id)">
      {{type.name}}
    </span>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="tab_content">
          <div class="tab_item">
            <ul *ngIf="technologies$ as technologies">
              <li *ngFor="let technology of technologies ">
                <img [src]="technology.icon" [alt]="technology.name" [title]="technology.name">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
