<footer>
  <div class="container">
    <div class="row">

      <div class="col-lg-7">
        <form action="" class="form" id="form">
          <h2 class="title">Contact Us</h2>
          <div class="row">
            <div class="col-lg-6 col-md-6"><input type="text" name="fullName" class="input input-name"
                                                  placeholder="Full name *" required="required"></div>
            <div class="col-lg-6 col-md-6"><input type="text" name="email" class="input input-email"
                                                  placeholder="E-mail *" required="required"></div>
            <div class="col-lg-6 col-md-6"><input type="text" name="phone" class="input input-phone tel-input"
                                                  placeholder="Phone number"></div>
            <div class="col-lg-6 col-md-6"><input type="text" name="company" class="input input-company"
                                                  placeholder="Company"></div>
            <div class="col-lg-12 col-md-12"><textarea name="message" class="input input-msg"
                                                       placeholder="Message *"></textarea></div>
          </div>
          <button class="btn-send" type="button" (click)="sendMessage()">Send message</button>
        </form>
      </div>

      <div class="col-lg-5 right-footer">
        <div class="map" id="map"></div>
        <div class="contacts-box">
          <div class="left" *ngIf="contact$ as contacts">
            <div class="item item-add">{{contacts.address}}</div>
            <div class="item item-phone"><a href="{{'tel:' + contacts.phoneNumber}}">{{contacts.phoneNumber}}</a></div>
            <div class="item item-email"><a href="{{'mailto:' + contacts.emailDisplay}}">{{contacts.emailDisplay}}</a></div>
            <div class="item" *ngIf="false"><a routerLink="/tos">Terms of Services </a> <a routerLink="/privacy-policy"> Privacy Policy</a></div>
          </div>
          <div class="right" *ngIf="socialNetworks$ as socialNetworks">
            <div class="logo"><img src="assets/img/logo2.svg" alt=""></div>
            <div class="soc-box">
              <ng-container *ngFor="let socialNetwork of socialNetworks">
                <a [href]="socialNetwork.link" target="_blank" (click)="socialNetworkClick(socialNetwork.name)">
                  <img [src]="socialNetwork.alterIcon" [alt]="socialNetwork.name" [title]="socialNetwork.name"></a>&nbsp;
              </ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</footer>
