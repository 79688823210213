import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Tag} from "../../model/tag";
import {Article} from "../../model/article";
import {LifeService} from "../../services/life.service";

@Component({
  selector: 'app-our-life',
  templateUrl: './our-life.component.html',
  styleUrls: ['./our-life.component.sass']
})
export class OurLifeComponent implements OnInit {

  title = 'GOLIVE! | Our life';
  c = 6;
  top = 0;
  tagFilterId = 0;

  tags$: Tag[];
  articles$: Article[];

  constructor(private titleService: Title, private lifeService: LifeService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.lifeService.loadTags()
      .subscribe((data: Tag[]) => {
        this.tags$ = data;
      });

    this.loadTopArticles(this.c);
  }

  getTop() {
    let a = this.articles$.length; // length of visible articles on page
    let b = a % this.c; // remainder of the division
    if (b > this.c / 2) {
      this.top = a + this.c + (this.c - b);
    } else {
      this.top = a + (this.c - b);
    }

    return this.top;
  }

  loadTopArticles(top) {
    this.lifeService.loadArticles(top)
      .subscribe((data: Article[]) => {
        this.articles$ = data;
      });
  }

  loadTopArticlesByTag(id: number, top: number) {
    this.lifeService.loadArticlesByTag(id, top)
      .subscribe((data: Article[]) => {
        this.articles$ = data;
      });
  }

  clickTag(event, id: number, top: number = this.c) {
    if (event.target.classList.contains('active')) {
      event.target.classList.remove('active');
      this.tagFilterId = 0;
      this.loadTopArticles(top);
    } else {
      let children = Array.from(event.target.parentElement.childNodes);
      let tags =  children.filter(x => (x as Element).tagName === "A");
      tags.forEach(tag => {
        (tag as Element).classList.remove('active');
      });
      event.target.classList.add('active');
      this.tagFilterId = id;
      this.loadTopArticlesByTag(id, top);
    }
  }

  seeMore() {
    if (this.tagFilterId > 0) {
      this.loadTopArticlesByTag(this.tagFilterId, this.getTop());
    } else {
      this.loadTopArticles(this.getTop());
    }
  }

  articleTrack(index: number, article: Article){
    return article.id;
  }

}
