import {BrowserModule, Title, Meta} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeModule} from "./modules/home.module";
import {SharedModule} from "./modules/shared.module";
import {AboutUsModule} from "./modules/about-us.module";
import {MainModule} from "./modules/main.module";
import {ArticleModule} from "./modules/article.module";
import {GoogleAnalyticsService} from "./services/google-analytics.service";
import {SlickCarouselModule} from "ngx-slick-carousel";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    FormsModule,
    AppRoutingModule,
    ArticleModule,
    HomeModule,
    SharedModule,
    AboutUsModule,
    MainModule,
    SlickCarouselModule
  ],
  providers: [
    Title,
    Meta,
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
