import { Injectable } from '@angular/core';

declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter(action: string, category: string, label: string = null, value: number = null){
    gtag('event', action, {
      'event_category': category,
      'event_label': label,
      'value': value
    });
  }
}
