import {Component, Input, OnInit} from '@angular/core';
import {SocialNetwork} from "../../../model/social-network";
import {MainService} from "../../../services/main.service";
import {GoogleAnalyticsService} from "../../../services/google-analytics.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  @Input()
  socialNetworks$: SocialNetwork[];

  headerClass = "";

  constructor(private mainService: MainService, private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit(): void {
  }

  ngAfterContentChecked(): void {
    this.headerClass = this.mainService.getHeaderClass();
  }

  scrollToForm(){
    document.getElementById("form").scrollIntoView({block: "center", behavior: "smooth"});
  }

  socialNetworkClick(name: string){
    this.googleAnalyticsService.eventEmitter("view_social_network", "social_networks", name);
  }

}
