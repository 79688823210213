<section class="how-work-page about-us-page content-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="intro$ as intro">
          {{intro}}
        </div>
        <div class="wrapper" *ngIf="workSteps$ as steps">
          <div class="tabs">
            <span class="tab" *ngFor="let step of steps; index as i">
              {{step.title}}<span class="num">{{i + 1 | number: '2.0'}}</span>
            </span>
          </div>
          <div class="tab_content">
            <div class="tab_item" *ngFor="let step of steps">
              <div class="row">
                <div class="col-lg-4">
                  <div class="image-box">
                    <img [src]="step.imageUrl" [alt]="step.title">
                  </div>
                </div>
                <div class="col-lg-8 pt20" [innerHTML]="step.descriptionSafe">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
