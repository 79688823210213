<header [class]="headerClass">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 wrapper">
        <div class="left"><a class="btn-contacts" (click)="scrollToForm()">Contact us</a></div>
        <div class="nav">
          <a routerLink="/about-us" routerLinkActive="active">About us</a>
          <!--          <a routerLink="/our-knowledge" routerLinkActive="active">Our knowledge</a>-->
          <div class="logo-box"><a routerLink="/"><img src="assets/img/logo.svg" alt="GOLIVE!"></a></div>
          <a routerLink="/our-solutions" routerLinkActive="active">Our solutions</a>
          <a routerLink="/our-life" routerLinkActive="active">Our life</a>
        </div>
        <div class="right">
          <div class="search-box" *ngIf="false">
            <form action="">
              <label>
                <input type="text" placeholder="Search">
              </label>
              <button class="btn-search"><i class="demo-icon icon-search"></i></button>
            </form>
          </div>
          <div class="soc-box" *ngIf="socialNetworks$ as socialNetworks">

            <ng-container *ngFor="let socialNetwork of socialNetworks">
              <a [href]="socialNetwork.link" target="_blank" (click)="socialNetworkClick(socialNetwork.name)">
                <img [src]="socialNetwork.icon" [alt]="socialNetwork.name" [title]="socialNetwork.name">
              </a>&nbsp;
            </ng-container>

          </div>
          <div class="lng-box" *ngIf="false">
            <ul>
              <li class="init">En</li>
              <li data-value="value 1">En</li>
              <li data-value="value 2">Ru</li>
            </ul>
          </div>
        </div>
        <button class="menu-button btn-menu menu-btn"><i class="demo-icon icon-open-menu"></i></button>
      </div>
    </div>
  </div>
</header>
