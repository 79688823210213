import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AboutUsComponent} from "../components/about-us/about-us.component";
import {HowWeWorkComponent} from "../components/about-us/how-we-work/how-we-work.component";
import {OurPartnersComponent} from "../components/about-us/our-partners/our-partners.component";
import {OurStoryComponent} from "../components/about-us/our-story/our-story.component";
import {RouterModule, Routes} from "@angular/router";
import {SlickCarouselModule} from "ngx-slick-carousel";
const routes: Routes = [
  {path: 'about-us', component: AboutUsComponent, data: {animation: 'AboutUs'}}
];

@NgModule({
  declarations: [
    AboutUsComponent,
    HowWeWorkComponent,
    OurPartnersComponent,
    OurStoryComponent,
  ],
  exports: [
    AboutUsComponent,
    HowWeWorkComponent,
    OurPartnersComponent,
    OurStoryComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SlickCarouselModule
  ]
})
export class AboutUsModule {
}
