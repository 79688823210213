<section class="pushy pushy-left" data-focus="#first-link">
  <div class="pushy-content">
    <div class="top-panel">
      <div class="lng-box" *ngIf="false">
        <ul>
          <li class="init">En</li>
          <li data-value="value 1">En</li>
          <li data-value="value 2">Ru</li>
        </ul>
      </div>
      <div class="search-box" *ngIf="false">
        <form action="">
          <input type="text" placeholder="Search">
          <button class="btn-search"><i class="demo-icon icon-search"></i></button>
        </form>
      </div>
    </div>
    <nav>
      <ul>
        <li><a routerLink="/" (click)="closeMenu()">Home</a></li>
        <li><a routerLink="/about-us" (click)="closeMenu()">About us</a></li>
<!--        <li><a routerLink="/our-knowledge" (click)="closeMenu()">Our knowledge</a></li>-->
        <li><a routerLink="/our-solutions" (click)="closeMenu()">Our solutions</a></li>
        <li><a routerLink="/our-life" (click)="closeMenu()">Our life</a></li>
        <li><a href="" (click)="scrollToForm()">Contact us</a></li>
      </ul>
    </nav>
  </div>
</section>
