import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pushy-menu',
  templateUrl: './pushy-menu.component.html',
  styleUrls: ['./pushy-menu.component.sass']
})
export class PushyMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  closeMenu(){
    $('.close-menu-btn').click();
  }

  scrollToForm(){
    event.preventDefault();
    this.closeMenu();
    document.getElementById("form").scrollIntoView({block: "center", behavior: "smooth"});
  }

}
