import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";



@Injectable({
  providedIn: 'root'
})
export class MainService {

  headerClass = "";

  constructor(private http: HttpClient) {
  }

  setHeaderClass(className: string) {
    this.headerClass = className;
  }

  getHeaderClass() {
    return this.headerClass;
  }

  doHttpGet(url: string) {
    return this.http.get(url, {responseType: "json"});
  }

  doAjaxRequest(url:string, data: object){
    return this.http.post(url, data);
  }

  loadSliders() {
    return this.doHttpGet("/api/slider/get-sliders");
  }

  loadServices(detailed: boolean) {
    return this.doHttpGet(`/api/service/get-services?detailed=${detailed}`);
  }

  loadWorkSteps(detailed: boolean) {
    return this.doHttpGet(`/api/work-step/get-steps?detailed=${detailed}`);
  }

  loadTechnologyTypes(detailed: boolean) {
    return this.doHttpGet(`/api/technology-types/get?detailed=${detailed}`);
  }

  loadTechnologiesByType(typeId: number) {
    return this.doHttpGet(`/api/technologies/get-by-type/${typeId}`);
  }

  loadCoreProducts() {
    return this.doHttpGet('/api/core-products/get');
  }

  loadPartners() {
    return this.doHttpGet('/api/partners/get');
  }

  loadFeedback(detailed: boolean) {
    return this.doHttpGet(`/api/feedbacks/get?detailed=${detailed}`);
  }

  loadArticles() {
    return this.doHttpGet('/api/articles/get');
  }

  loadContacts() {
    return this.doHttpGet('/api/settings/get-contacts');
  }

  loadSocialNetworks() {
    return this.doHttpGet('/api/social-networks/get');
  }

  loadStory() {
    return this.doHttpGet('/api/teams/get');
  }

  loadHowWeWorkIntro(){
    return this.doHttpGet('/api/settings/get-how-we-work-intro');
  }

  loadOurPartnersIntro(){
    return this.doHttpGet('/api/settings/get-our-partners-intro');
  }

  loadTos(){
    return this.doHttpGet('/api/settings/get-tos');
  }

  loadPrivacyPolicy(){
    return this.doHttpGet('/api/settings/get-privacy-policy');
  }

  sendMessage(data){
    return this.doAjaxRequest('/form/send-message', data);
  }

  initSliderSyncing(single, nav, singleConfig, navConfig) {
    // @ts-ignore
    $(single).slick(singleConfig);
    // @ts-ignore
    $(nav).slick(navConfig);

    $(single).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      // @ts-ignore
      $(nav).slick('slickGoTo', nextSlide);

      const nextNavSlideElem = nav.find(`.slick-slide[data-slick-index="${nextSlide}"]`);

      $(nav).find('.slick-slide.slick-current').removeClass('slick-current');
      nextNavSlideElem.addClass('slick-current');
    });

    $(nav).find('.btn-prev').on('click', function (event) {
      event.preventDefault();
      // @ts-ignore
      $(single).slick('slickPrev');
    });

    $(nav).find('.btn-next').on('click', function (event) {
      event.preventDefault();
      // @ts-ignore
      $(single).slick('slickNext');
    });

    nav.on('click', '.slick-slide', function (event) {
      event.preventDefault();
      const clickedIndex = $(this).data('slick-index');
      // @ts-ignore
      $(single).slick('slickGoTo', clickedIndex);
    });

  }

  prependScript(src: string, elem: HTMLElement, attrs = null){
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    if (attrs){
      attrs.forEach(attr => {
        script.setAttribute(attr.name, attr.value);
      });
    }

    elem.prepend(script);
  }

  appendScript(src: string, elem: HTMLElement, attrs = null){
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    if (attrs){
      attrs.forEach(attr => {
        script.setAttribute(attr.name, attr.value);
      });
    }

    elem.append(script);
  }
}
