import {AfterViewChecked, Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {TechnologyType} from "../../../model/technology-type";
import {MainService} from "../../../services/main.service";
import {Technology} from "../../../model/technology";
import {NgsRevealConfig} from "ngx-scrollreveal";

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.sass']
})
export class TechnologiesComponent implements OnInit, AfterViewChecked {

  @ViewChildren('tabs')
  tabs: QueryList<ElementRef>

  technologyTypes$: TechnologyType[];
  technologies$:Technology[];
  firstType: TechnologyType;

  config: NgsRevealConfig;

  init = false;

  constructor(private mainService:MainService) {
    this.config =  { delay: 300,  reset: false, afterReveal: function (el) {
        el.classList.add('is-visible');
      }, afterReset: function (el) {
        el.classList.remove('is-visible');
      }};
  }

  ngAfterViewChecked(): void {
    let tab = $(".wrapper .tab");
    let tabItem = $(".tab_item");
    let techStack = $('.tech-stack');

    if (!this.init && tab.length > 0 && tabItem.length > 0 && techStack.length > 0) {
      this.init = true;

      tabItem.not(":first").hide();
      tab.click(function () {
        tab.removeClass("active").eq($(this).index()).addClass("active");
      }).eq(0).addClass("active");

      $(".tech-stack .btn-open-tab-mobile").click(function () {
        $(".tech-stack .tabs").toggleClass("active");
      });

      $(".tech-stack .tabs .tab").click(function () {
        $(".tech-stack .tabs").removeClass("active");
      });
    }
  }

  ngOnInit(): void {

   this.mainService.loadTechnologyTypes(false)
     .subscribe((data: TechnologyType[]) => {
       this.technologyTypes$ = data;
       this.firstType = this.technologyTypes$[0];
       this.getTechnologiesByType(event, this.firstType.id);
     });
  }

  getTechnologiesByType(event, typeId:number){

    this.mainService.loadTechnologiesByType(typeId)
      .subscribe((data: Technology[]) => {
        this.technologies$ = data;
      });

    if (event.type == 'click'){
      $(".wrapper .tab").removeClass("active");
      event.target.classList.add('active');
    }
  }



}
