import { Component, OnInit } from '@angular/core';
import {MainService} from "../../../services/main.service";
import {CoreProduct} from "../../../model/core-product";
import {Partner} from "../../../model/partner";
import {NgsRevealConfig} from 'ngx-scrollreveal';
import {GoogleAnalyticsService} from "../../../services/google-analytics.service";

@Component({
  selector: 'app-black-block',
  templateUrl: './black-block.component.html',
  styleUrls: ['./black-block.component.sass']
})
export class BlackBlockComponent implements OnInit {

  coreSlider = {
    dots: true,
    speed: 1000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '',
    nextArrow: '',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  partnersSlider = {
    dots: true,
    speed: 1000,
    infinite: true,
    vertical: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: '',
    nextArrow: '',
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3

        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          vertical: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          vertical: false
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  $products:CoreProduct[];
  $partners: Partner[];

  config: NgsRevealConfig;

  constructor(private mainService:MainService, private googleAnalyticsService: GoogleAnalyticsService) {
    this.config =  { delay: 300,  reset: false, afterReveal: function (el) {
        el.classList.add('is-visible');
      }, afterReset: function (el) {
        el.classList.remove('is-visible');
      }};
  }

  ngOnInit(): void {
    this.mainService.loadCoreProducts()
      .subscribe((data: CoreProduct[]) => {
        this.$products = data;
      });

    this.mainService.loadPartners()
      .subscribe((data: Partner[]) => {
        this.$partners = data;
      });
  }

  coreProductsClick(name: string){
    this.googleAnalyticsService.eventEmitter('view_core_products_details', 'core_products', name);
  }

}
