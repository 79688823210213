import { Component, OnInit } from '@angular/core';
import {Story} from "../../../model/story";
import {MainService} from "../../../services/main.service";

@Component({
  selector: 'app-our-story',
  templateUrl: './our-story.component.html',
  styleUrls: ['./our-story.component.sass']
})
export class OurStoryComponent implements OnInit {

  story$: Story;

  constructor(private mainService:MainService) { }

  ngOnInit(): void {
    this.mainService.loadStory()
      .subscribe((data: Story) => {
        this.story$ = data;
      });
  }

}
